import React from "react";
import {
  FilledButton,
  OutlinedButton,
  Text,
} from "@ifgengineering/component-library";
import ModalWindow from "@components/ModalWindow";
import { ModalButtonsWrapper, ModalWrapper } from "./styled";

interface ISkipOnboardingBtn {
  onClick: VoidFunction;
}

export const SkipOnboardingBtn: React.FC<ISkipOnboardingBtn> = ({
  onClick,
}) => {
  return (
    <OutlinedButton
      width="fit-content"
      icon="RightSmallUp"
      iconPosition="right"
      text="Skip Onboarding"
      onClick={onClick}
      testId="skip-onboarding-button"
    />
  );
};

interface ISkipOnboardingModal {
  showModal: boolean;
  onClose?: VoidFunction;
  onClickSkip?: VoidFunction;
}

export const SkipOnboardingModal: React.FC<ISkipOnboardingModal> = ({
  showModal,
  onClose,
  onClickSkip,
}) => {
  return (
    <ModalWindow show={showModal} onClose={onClose}>
      <ModalWrapper>
        <Text type="T24" color="SLATE900" fontFamily="archiabold">
          Skip Investor Profile
        </Text>
        <Text type="P16" color="SLATE900">
          You can always return to this page later. You will need to complete
          your investor profile before being able to invest.
        </Text>
        <ModalButtonsWrapper>
          <OutlinedButton
            text="Don't skip"
            width="150px"
            height="40px"
            onClick={onClose}
          />
          <FilledButton
            color="blue"
            text="Skip Investor Profile"
            width="180px"
            height="40px"
            testId="skip-investor-profile-button"
            onClick={onClickSkip}
          />
        </ModalButtonsWrapper>
      </ModalWrapper>
    </ModalWindow>
  );
};
