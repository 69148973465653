import { useEffect } from "react";

const useOpenIosFallback = () => {
  useEffect(() => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isIOS) {
      const pathname = window.location.pathname.replace(/^\/+/, "");
      const search = window.location.search;
      window.location.href = `cur8://${pathname}${search}`;

      // use if we want to force open app store
      // const appStoreLink = "https://apps.apple.com/app/id1665655843";
      // setTimeout(() => {
      //   window.location.href = appStoreLink;
      // }, 2000);
    }
  }, []);
};

export default useOpenIosFallback;
