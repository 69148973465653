import React, { FC, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import Cur8StyleOtpForm from "../../otp/Cur8StyleOtpForm";
import {
  signInAppleAsync,
  signInAsync,
  signInGoogleAsync,
} from "@state/auth/auth.actions";
import { authLoadingSelector } from "@state/auth/auth.selectors";
import {
  LoginInput,
  TwoFactorAuthMethod,
} from "@ifgengineering/client-auth-sdk";
import { authSdk } from "@utils/auth";
import { authBgColor } from "./consts";
import { logException } from "@helpers/logException/logException";
import {
  LayoutShell,
  PromoTwo,
  PromoTwoBody,
  Signin,
  useClientAuthComponents,
} from "@ifgengineering/client-auth-components";
import RegulatoryBanner from "@components/RegulatoryBanner/RegulatoryBanner";
import { useGoogleLogin } from "@react-oauth/google";
import { APPLE_CLIENT_ID } from "gatsby-env-variables";
import { AppleAuthResponseData } from "@ifgengineering/client-auth-components/src/components/Widget/pages/Signin/types";
import { PublicRouteProps } from "../../../pages/app";
import getAndStoreAppleIdToken from "@helpers/getAndStoreAppleIdToken";

export const LoginContainer: FC<PublicRouteProps> = () => {
  const dispatch = useDispatch();
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [emailAndPassword, setEmailAndPassword] = useState({
    email: "",
    password: "",
  });
  const [twoFactorAuthMethod, setTwoFactorMethod] =
    useState<TwoFactorAuthMethod>();
  const isLoading = useSelector(authLoadingSelector);
  const signInUser = (user: LoginInput) => dispatch(signInAsync(user));
  const [errorMessage, setErrorMessage] = useState("");
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const { setAppleAuthData } = useClientAuthComponents();

  const resendOtp = () => {
    authSdk.generateOtp(emailAndPassword.email);
  };

  useEffect(() => {
    setErrorMessage("");
  }, []);

  useEffect(() => {
    isAuthenticated && navigate("/app");
  }, [isAuthenticated]);

  const navigateToPage = () => {
    const intendedVisitLink = localStorage.getItem("intended_visit_link");
    const loginRedirect = intendedVisitLink || "/app/deals";
    navigate(loginRedirect);
  };

  const handleLoginSubmit = async ({
    email,
    password,
    captchaToken,
  }: {
    email: string;
    password: string;
    captchaToken: string;
  }) => {
    try {
      const { isTwoFactorAuthEnabled, primaryTwoFactorAuthMethod, error } =
        await authSdk.getTwoFactorAuthDetails({
          email,
          password,
          captchaToken,
        });

      if (error) {
        if (error.statusCode === 429) {
          setErrorMessage(error.message);
          return;
        }

        if (error.response) {
          if (error.response.status === 422) {
            setErrorMessage("Please set a new password");
            return;
          }

          if (error.response.status === 401) {
            setErrorMessage("Invalid email or password");
            return;
          }
        }

        logException({
          scopeEmail: email,
          tag: "getTwoFactorAuthDetails",
          exception: error,
        });

        setErrorMessage(error.message || JSON.stringify(error));
        return;
      }

      if (isTwoFactorAuthEnabled && primaryTwoFactorAuthMethod) {
        setEmailAndPassword({ email, password });
        setTwoFactorMethod(primaryTwoFactorAuthMethod);
        setShowOtpForm(true);
        authSdk.generateOtp(email);
        return;
      }

      const res = (await signInUser({ email, password, captchaToken })) as any;
      if (res?.payload?.message == "Login successful") {
        navigateToPage();
      }
    } catch (error) {
      logException({
        scopeEmail: email,
        exception: error,
      });

      setErrorMessage("Something went wrong! Try again later.");
    }
  };

  const handleOtpSubmit = async ({ otp }: { otp: string }) => {
    const res = (await signInUser({
      ...emailAndPassword,
      otp,
    })) as any;
    if (res?.payload?.message == "Login successful") {
      navigateToPage();
    }
    if (res.payload?.response?.status === 401)
      setErrorMessage("Invalid or expired magic code.");
  };
  const handleGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/userinfo.email",
    ux_mode: "popup",
    onSuccess: async (codeRes) =>
      dispatch(signInGoogleAsync({ authorizationCode: codeRes.code })),
    onError: (errorResponse: any) => console.log(errorResponse),
  });

  const handleAppleLogin = async (data: AppleAuthResponseData) => {
    const { id_token, code } = data.authorization;
    const { token: appleIdToken } = getAndStoreAppleIdToken(id_token);
    const isTokenAndCodePresent = id_token && code && appleIdToken;
    if (isTokenAndCodePresent) {
      setAppleAuthData({ authorizationCode: code });
      try {
        const res = await dispatch(
          signInAppleAsync({
            authorizationCode: code,
            appleIdToken: appleIdToken,
          })
          // @ts-expect-error unwrap is not typed
        ).unwrap();
        const isPrivateEmail = res?.isPrivateRelayEmail;
        if (isPrivateEmail) {
          setAppleAuthData({ isPrivateEmail });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const APPLE_REDIRECT_URI = location.origin;

  return (
    <LayoutShell
      Banner={() => <RegulatoryBanner />}
      Promo={() => <PromoTwo />}
      MobileFooter={() => <PromoTwoBody />}
      showHeader={true}
      showTrustBox={true}
      backgroundColor={authBgColor}
      isLayoutBEnabled={false}
    >
      {showOtpForm ? (
        <Cur8StyleOtpForm
          otpMethod={twoFactorAuthMethod}
          resendOtp={resendOtp}
          handleSubmit={handleOtpSubmit}
          errorMessage={errorMessage}
          isLoading={isLoading}
          userEmail={emailAndPassword.email}
          handleCancel={() => {
            setErrorMessage("");
            setShowOtpForm(false);
          }}
        />
      ) : (
        <Signin
          loginRedirect="/app/deals"
          forgotPasswordURL="/app/forgot-password"
          signupURL="/app/signup"
          onSubmit={handleLoginSubmit}
          title="Login"
          enableMagicCode={true}
          errorMessage={errorMessage}
          isLoading={isLoading}
          googleLogin={{
            enable: true,
            onClick: handleGoogleLogin,
          }}
          appleLogin={{
            enable: true,
            clientId: APPLE_CLIENT_ID,
            redirectURI: APPLE_REDIRECT_URI,
            onClick: handleAppleLogin,
          }}
        />
      )}
    </LayoutShell>
  );
};

export default LoginContainer;
