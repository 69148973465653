import React from "react";
import {
  Wrapper,
  Cur8Logo,
  PoweredByLogo,
  Header,
  Body,
  AppStoreImg,
  ButtonWrapper,
} from "@components/BlockMobile/styled";
import Cur8LogoLight from "@assets/cur8/img/cur8-white.png";
import PoweredByIFGLogo from "@assets/images/powered-by-ifg.png";
import DownloadAppStore from "@assets/images/download-app-store.svg";
import DownloadGooglePlay from "@assets/images/get-on-google-play.png";

import { Text } from "@ifgengineering/component-library";
import useOpenIosFallback from "../../hooks/useOpenIosFallback";

const BlockMobile = () => {
  useOpenIosFallback();
  return (
    <Wrapper>
      <Header>
        <Cur8Logo src={Cur8LogoLight} />
        <PoweredByLogo height={33} src={PoweredByIFGLogo} />
      </Header>
      <Body>
        <Text type={"T24"} color="WHITE">
          For mobile users, Cur8 is accessible solely through our app.
        </Text>
        <Text type={"P16"} color="WHITE">
          For a smooth and secure investing experience on mobile, please
          download the free Cur8 App.
        </Text>
      </Body>

      <ButtonWrapper>
        <AppStoreImg
          src={DownloadAppStore}
          onClick={() => {
            window.location.href =
              "https://apps.apple.com/gb/app/cur8-by-ifg/id1665655843";
          }}
        />
        <AppStoreImg
          src={DownloadGooglePlay}
          onClick={() => {
            window.location.href =
              "https://play.google.com/store/apps/details?id=capital.cur8&hl=en_US";
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default BlockMobile;
